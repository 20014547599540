@import url('https://fonts.googleapis.com/css?family=Oswald&subset=latin-ext'); //'Oswald', sans-serif;

$global-link-color: rgb(195, 54, 42);

// place for variables
$global-color: #333; //8
$global-primary-background: rgb(195, 54, 42); //25
$global-muted-color: #000; //57
$global-secondary-background: #222; //65
$global-border: rgb(195, 54, 42); //98
$global-border-width: 3px; //129
$offcanvas-bar-padding-vertical: 40px; //345
$offcanvas-bar-padding-horizontal: 40px; //346
$offcanvas-bar-background: white; //347
$offcanvas-bar-color-mode: darken; //348
$offcanvas-overlay-background: rgba(31, 31, 31, 0.9); //354
$global-font-family: 'Oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; //507
$base-selection-background: $global-link-color; //930

@import "~uikit/src/scss/variables";
@import "~uikit/src/scss/mixins";

// place fo my mixins

@mixin hook-dotnav-item() { //241
  display: block;
  box-sizing: border-box;
  width: 22px;
  height: 5px;
  border-radius: 0;
  background: #e0e0e0;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  transition: .2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
}

@mixin hook-dotnav-item-active() { //244
  background: rgba(102, 102, 102, 0.6);
}

@mixin hook-heading-hero() { //289
  color: rgb(195, 54, 42);
  //text-shadow: 3px 3px 3px #333;
}


@mixin hook-slidenav() { //1278
  padding: 21px 18px;
  color: #000;
  background: #fff;
  transition: .1s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
}

@mixin hook-card-media() { //1373
  > .uk-card-media-top > div > div {
    background-color: rgba($global-link-color, 0.7);
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    -webkit-transition-property: background-color;
    transition-property: background-color;
  }
  &:hover > .uk-card-media-top > div > div {
    background-color: rgba($global-link-color, 1);
  }
}

@mixin hook-navbar-nav-item-active() { //1408
  color: $global-link-color;
}

@mixin hook-navbar-container() { //1476
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 3px solid $global-link-color;
}

@mixin hook-navbar-nav-item() { //1477
  color: #b2b2b2;
  transition: .2s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  letter-spacing: -.6px;
  text-transform: uppercase;
}

@mixin hook-navbar-nav-item-hover() { //1478
  color: $global-link-color;
}

@mixin hook-base-link-hover() { //1609
  text-decoration: none;
}

@import "~uikit/src/scss/uikit";

// place for pure css

@media (min-width: 960px) {
  .tsf-page {
    border: 30px solid transparent;
  }
  .uk-sticky-fixed > .uk-navbar-container:not(.uk-navbar-transparent) {
    transition: .2s linear;
    transition-property: padding;
  }
}

@media (min-width: 1200px) {
  .tsf-page {
    border-width: 50px;
  }
}

.tsf-page {
  border-top-width: 0;
  position: relative;
}

i.uk-icon {
  margin-right: 8px;
}

*:focus {
  outline: none;
}
